import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { usePage, useAddRouterEventListener } from "@tecnobit-srl/router";
import { createContext, useContext, useState, useRef, useMemo, useEffect, useCallback } from "react";
import { isBoolean, each, omitBy, filter, values, isNil, isEmpty, map, forOwn, includes, mapValues, isArray, join, pickBy } from "lodash-es";
import { Script } from "@tecnobit-srl/script-manager";
import { zIndexes } from "@tecnobit-srl/ui-zindexes";
import { Head } from "@tecnobit-srl/head-manager";
import { v4 } from "uuid";
import axios from "axios";
const context = createContext({
  consentGiven: false,
  initialized: false
});
const { Provider } = context;
const useIubendaConsent = () => {
  return useContext(context);
};
function IubendaConsentProvider({
  locale,
  siteId,
  cookiePolicyId,
  children,
  banner
}) {
  const [initialized, setInitialized] = useState(false);
  const initializing = useRef(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const sanitizedLocale = useMemo(
    () => locale.split("-")[0].toLowerCase(),
    [locale]
  );
  const [addScripts, setAddScripts] = useState(false);
  useEffect(() => {
    if (!initializing.current && !initialized) {
      initializing.current = true;
      window._iub = window._iub ?? {};
      window._iub.csConfiguration = {
        ...{
          askConsentAtCookiePolicyUpdate: true,
          cookiePolicyInOtherWindow: true,
          countryDetection: true,
          enableFadp: true,
          enableLgpd: true,
          enableUspr: true,
          floatingPreferencesButtonDisplay: "bottom-left",
          lgpdAppliesGlobally: false,
          perPurposeConsent: true,
          usPreferencesWidgetDisplay: "inline-left",
          floatingPreferencesButtonZIndex: zIndexes.floatingButtons,
          banner: {
            acceptButtonCaptionColor: (banner == null ? void 0 : banner.acceptButtonCaptionColor) ?? "#FBFBFB",
            acceptButtonColor: (banner == null ? void 0 : banner.acceptButtonColor) ?? "#0073CE",
            acceptButtonDisplay: true,
            backgroundColor: (banner == null ? void 0 : banner.backgroundColor) ?? "#FFFFFF",
            backgroundOverlay: true,
            closeButtonRejects: true,
            customizeButtonCaptionColor: (banner == null ? void 0 : banner.customizeButtonCaptionColor) ?? "#FBFBFB",
            customizeButtonColor: (banner == null ? void 0 : banner.customizeButtonColor) ?? "#0073CE",
            customizeButtonDisplay: true,
            explicitWithdrawal: true,
            fontSizeBody: "12px",
            fontSizeCloseButton: "26px",
            listPurposes: true,
            logo: null,
            linksColor: (banner == null ? void 0 : banner.linksColor) ?? "#0073CE",
            ownerName: "tecnobit.it",
            position: "float-top-center",
            rejectButtonCaptionColor: (banner == null ? void 0 : banner.rejectButtonCaptionColor) ?? "#FBFBFB",
            rejectButtonColor: (banner == null ? void 0 : banner.rejectButtonColor) ?? "#0073CE",
            rejectButtonDisplay: true,
            showPurposesToggles: true,
            showTotalNumberOfProviders: true,
            textColor: (banner == null ? void 0 : banner.textColor) ?? "#333333"
          }
        },
        ...{
          lang: sanitizedLocale,
          siteId,
          cookiePolicyId,
          callback: {
            onReady: () => {
              const consent = window._iub.cs.api.isConsentGiven();
              if (isBoolean(consent)) {
                setConsentGiven(consent);
              }
              setInitialized(true);
              initializing.current = false;
            },
            onConsentFirstGiven: () => {
              setConsentGiven(true);
            },
            onConsentFirstRejected: () => {
              setConsentGiven(false);
            }
          }
        }
      };
      setAddScripts(true);
    }
  }, [
    banner == null ? void 0 : banner.acceptButtonCaptionColor,
    banner == null ? void 0 : banner.acceptButtonColor,
    banner == null ? void 0 : banner.backgroundColor,
    banner == null ? void 0 : banner.customizeButtonCaptionColor,
    banner == null ? void 0 : banner.customizeButtonColor,
    banner == null ? void 0 : banner.linksColor,
    banner == null ? void 0 : banner.rejectButtonCaptionColor,
    banner == null ? void 0 : banner.rejectButtonColor,
    banner == null ? void 0 : banner.textColor,
    cookiePolicyId,
    initialized,
    sanitizedLocale,
    siteId
  ]);
  const providerValues = useMemo(
    () => ({ consentGiven, initialized }),
    [consentGiven, initialized]
  );
  return /* @__PURE__ */ jsxs(Provider, { value: providerValues, children: [
    addScripts ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Script,
        {
          src: "//cdn.iubenda.com/cs/tcf/stub-v2.js",
          async: true
        },
        "iubenda-tcf"
      ),
      /* @__PURE__ */ jsx(
        Script,
        {
          src: "//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js",
          async: true
        },
        "iubenda-safe-tcf"
      ),
      /* @__PURE__ */ jsx(
        Script,
        {
          src: "//cdn.iubenda.com/cs/gpp/stub.js",
          async: true
        },
        "iubenda-gpp"
      ),
      /* @__PURE__ */ jsx(
        Script,
        {
          src: "//cdn.iubenda.com/cs/iubenda_cs.js",
          async: true
        },
        "iubenda-cs"
      )
    ] }) : null,
    children
  ] });
}
function FacebookPixel() {
  const { settings, user } = usePage();
  const [ready, setReady] = useState(false);
  const initializing = useRef(false);
  const { consentGiven, initialized: iubendaInizialized } = useIubendaConsent();
  useEffect(() => {
    if (initializing.current) {
      return;
    }
    initializing.current = true;
    let fbq = window.fbq;
    if (!fbq) {
      fbq = function(...args) {
        if (fbq) {
          if (fbq.callMethod) {
            fbq.callMethod(...args);
          } else {
            fbq.queue.push(args);
          }
        }
      };
      fbq.push = fbq;
      fbq.queue = [];
      fbq.loaded = true;
      fbq.version = "2.0";
    }
    window.fbq = fbq;
    if (!window._fbq) {
      window._fbq = fbq;
    }
    each(settings.fbPixelIds, (_validation, pixelId) => {
      var _a;
      sendFbInit(
        pixelId,
        user ? omitBy(
          {
            em: user.email,
            fn: user.firstname,
            ln: user.lastname,
            ph: user.mobilephone ?? user.phone
          },
          (v) => !v
        ) : void 0
      );
      (_a = window.fbq) == null ? void 0 : _a.call(window, "track", "PageView");
    });
    setReady(true);
  }, [ready, settings.fbPixelIds, user]);
  useEffect(() => {
    var _a;
    if (iubendaInizialized && ready) {
      (_a = window.fbq) == null ? void 0 : _a.call(window, "consent", consentGiven ? "grant" : "revoke");
    }
  }, [ready, consentGiven, iubendaInizialized]);
  if (!ready || !iubendaInizialized) {
    return null;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(FacebookValidation, {}),
    /* @__PURE__ */ jsx(Script, { src: "https://connect.facebook.net/en_US/fbevents.js", async: true })
  ] });
}
function FacebookValidation() {
  const { settings } = usePage();
  const validations = useMemo(
    () => filter(values(settings.fbPixelIds), (v) => !isNil(v) && !isEmpty(v)),
    [settings.fbPixelIds]
  );
  return !isEmpty(validations) ? /* @__PURE__ */ jsx(Head, { headKey: "fb-validation", children: map(
    validations,
    (v) => v ? /* @__PURE__ */ jsx(
      "meta",
      {
        name: "facebook-domain-verification",
        content: v
      },
      `facebook-validation-${v}`
    ) : null
  ) }) : null;
}
const sendFbInit = (pixelId, user) => {
  const fbq = window.fbq;
  if (fbq) {
    fbq("init", pixelId, user);
  } else {
    throw new Error("FbPixel not initialized");
  }
};
const sendFbPixelEvent = (eventId, props, opts) => {
  const fbq = window.fbq;
  if (fbq) {
    fbq("trackCustom", eventId, props, opts);
  } else {
    throw new Error("FbPixel not initialized");
  }
};
const methods = [
  "page",
  "track",
  "identify",
  "instances",
  "debug",
  "on",
  "off",
  "once",
  "ready",
  "alias",
  "group",
  "enableCookie",
  "disableCookie",
  "holdConsent",
  "revokeConsent",
  "grantConsent"
];
function TiktokPixel() {
  const { settings } = usePage();
  const [ready, setReady] = useState(false);
  const initializing = useRef(false);
  const { initialized: iubendaInizialized, consentGiven } = useIubendaConsent();
  useEffect(() => {
    if (initializing.current) {
      return;
    }
    initializing.current = true;
    const ttq = window.ttq ?? [];
    const setAndDefer = (target, method) => {
      target[method] = (...args) => {
        target.push([method, ...args]);
      };
    };
    for (const method of methods) {
      setAndDefer(ttq, method);
    }
    ttq.instance = (id) => {
      var _a;
      const instance = ((_a = ttq._i) == null ? void 0 : _a[id]) ? ttq._i[id] : [];
      for (const method of methods) {
        setAndDefer(instance, method);
      }
      return instance;
    };
    for (const tiktokPixelId of settings.tiktokPixelIds) {
      ttq._i = ttq._i ?? {};
      ttq._i[tiktokPixelId] = [];
      ttq._i[tiktokPixelId]._u = "https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq._t = ttq._t ?? {};
      ttq._t[tiktokPixelId] = Number(/* @__PURE__ */ new Date());
      ttq._o = ttq._o ?? {};
      ttq._o[tiktokPixelId] = void 0;
    }
    ttq.page();
    window.TiktokAnalyticsObject = "ttq";
    window.ttq = ttq;
    setReady(true);
  }, [ready, settings.tiktokPixelIds]);
  useEffect(() => {
    var _a, _b;
    if (iubendaInizialized && ready) {
      if (consentGiven) {
        (_a = window.ttq) == null ? void 0 : _a.grantConsent();
      } else {
        (_b = window.ttq) == null ? void 0 : _b.revokeConsent();
      }
    }
  }, [ready, consentGiven, iubendaInizialized]);
  if (!ready || !iubendaInizialized) {
    return null;
  }
  return /* @__PURE__ */ jsx(Fragment, { children: map(settings.tiktokPixelIds, (tiktokPixelId) => /* @__PURE__ */ jsx(
    Script,
    {
      src: `https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=${tiktokPixelId}&lib=ttq`,
      async: true
    },
    tiktokPixelId
  )) });
}
const sendTiktokPixelEvent = (eventId, ...props) => {
  var _a;
  (_a = window.ttq) == null ? void 0 : _a.track(eventId, ...props);
};
function Gtag() {
  const { settings, route } = usePage();
  const [ready, setReady] = useState(false);
  const initializing = useRef(false);
  const { initialized: iubendaInizialized } = useIubendaConsent();
  useEffect(() => {
    if (initializing.current) {
      return;
    }
    initializing.current = true;
    window.dataLayer = window.dataLayer ?? [];
    window.gtag = window.gtag ?? // eslint-disable-next-line func-names -- needed for gtag to work
    function() {
      var _a;
      (_a = window.dataLayer) == null ? void 0 : _a.push(arguments);
    };
    window.gtag("js", /* @__PURE__ */ new Date());
    each([...settings.analyticsIds, ...settings.adwordsIds], (pixelId) => {
      var _a;
      (_a = window.gtag) == null ? void 0 : _a.call(window, "config", pixelId, {
        page_path: route.fullUrl,
        content_group: route.type,
        anonymize_ip: true,
        linker: {
          domains: ["tecnobit.it"]
        }
      });
    });
    setReady(true);
  }, [
    ready,
    route.fullUrl,
    route.type,
    settings.adwordsIds,
    settings.analyticsIds
  ]);
  const addRouterEventListener = useAddRouterEventListener();
  useEffect(() => {
    return addRouterEventListener("navigate", (_url, newProps) => {
      each([...settings.analyticsIds, ...settings.adwordsIds], (pixelId) => {
        var _a;
        (_a = window.gtag) == null ? void 0 : _a.call(window, "config", pixelId, {
          page_path: newProps.route.fullUrl,
          content_group: newProps.route.type,
          anonymize_ip: true,
          linker: {
            domains: ["tecnobit.it"]
          }
        });
      });
    });
  }, [addRouterEventListener, settings.adwordsIds, settings.analyticsIds]);
  if (!ready || !iubendaInizialized) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    Script,
    {
      src: `https://www.googletagmanager.com/gtag/js?id=${settings.analyticsIds[0] ?? ""}`,
      async: true
    }
  );
}
const sendGtagEvent = (eventId, {
  category,
  label,
  value
}) => {
  const gtag = window.gtag;
  if (gtag) {
    gtag("event", eventId, {
      event_category: category,
      event_label: label,
      value
    });
  } else {
    throw new Error("Gtag not initialized");
  }
};
function Hubspot() {
  const { settings } = usePage();
  const [ready, setReady] = useState(false);
  const initializing = useRef(false);
  const [hasLoadedChat, setHasLoadedChat] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);
  const { consentGiven, initialized: iubendaInizialized } = useIubendaConsent();
  useEffect(() => {
    if (initializing.current) {
      return;
    }
    initializing.current = true;
    window.hsConversationsOnReady = [
      () => {
        setHasLoadedChat(true);
      }
    ];
    setReady(true);
  }, [ready]);
  useEffect(() => {
    if (loaded) {
      window._hsq = window._hsq ?? [];
      window._hsq.push(["setPath", window.location.pathname]);
      window._hsq.push(["trackPageView"]);
    }
  }, [loaded]);
  const addRouterEventListener = useAddRouterEventListener();
  useEffect(() => {
    return addRouterEventListener("navigate", (_url, newProps) => {
      window._hsq = window._hsq ?? [];
      window._hsq.push(["setPath", newProps.route.fullUrl]);
      window._hsq.push(["trackPageView"]);
      if (hasLoadedChat) {
        const status = window.HubSpotConversations.widget.status();
        if (!status.loaded) {
          window.HubSpotConversations.widget.load();
        } else {
          window.HubSpotConversations.widget.refresh();
        }
      }
    });
  }, [addRouterEventListener, hasLoadedChat]);
  if (!ready || !iubendaInizialized || !consentGiven) {
    return null;
  }
  return /* @__PURE__ */ jsx(Fragment, { children: map(settings.hubspotPortalIds, (portalId) => /* @__PURE__ */ jsx(
    Script,
    {
      id: "hs-script-loader",
      src: `//js.hs-scripts.com/${portalId}.js`,
      onload: onLoad,
      async: true
    },
    `hubspot_${portalId}`
  )) });
}
async function sendHubspotForm(hubspotPortalIds, requestData, utk, url) {
  if (hubspotPortalIds.length > 0) {
    const filter2 = [
      "email",
      "firstname",
      "lastname",
      "phone",
      "phone_prefix",
      "phone_number",
      "captcha"
    ];
    const labelToNameMap = {};
    forOwn(requestData.data, (__, k) => {
      if (!includes(filter2, k)) {
        labelToNameMap[k] = k;
      }
    });
    let formValues = mapValues(
      requestData.data,
      (v) => isArray(v) ? join(v, ", ") : v
    );
    formValues = pickBy(formValues, (__, k) => {
      return !includes(filter2, k);
    });
    await Promise.all(
      map(hubspotPortalIds, async (portalId) => {
        const submission = {
          contactFields: {
            email: requestData.data.email,
            firstName: requestData.data.firstname,
            lastName: requestData.data.lastname,
            phone: requestData.data.phone ?? requestData.data.phone_number
          },
          formSelectorClasses: "",
          formSelectorId: `#${requestData.id}`,
          formValues,
          labelToNameMap,
          pageTitle: url,
          pageUrl: url,
          portalId: portalId ? parseInt(portalId, 10) : 0,
          type: "SCRAPED",
          utk,
          uuid: v4(),
          version: "collected-forms-embed-js-static-1.247"
        };
        await axios.post(
          "https://forms.hubspot.com/collected-forms/submit/form",
          submission
        );
      })
    );
  } else {
    throw new Error("Missing hubspot portal id");
  }
}
function LinkedinInsight() {
  const { settings } = usePage();
  const [ready, setReady] = useState(false);
  const { consentGiven, initialized: iubendaInizialized } = useIubendaConsent();
  const initializing = useRef(false);
  useEffect(() => {
    if (initializing.current) {
      return;
    }
    initializing.current = true;
    window._linkedin_partner_id = settings.linkedinInsightIds[0];
    window._linkedin_data_partner_ids = [
      ...window._linkedin_data_partner_ids ?? [],
      ...settings.linkedinInsightIds
    ];
    setReady(true);
  }, [consentGiven, ready, settings.linkedinInsightIds]);
  if (!ready || !iubendaInizialized || !consentGiven) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    Script,
    {
      src: "https://snap.licdn.com/li.lms-analytics/insight.min.js",
      async: true
    }
  );
}
function Profilers() {
  const { consentGiven } = useIubendaConsent();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(FacebookPixel, {}),
    /* @__PURE__ */ jsx(TiktokPixel, {}),
    /* @__PURE__ */ jsx(Gtag, {}),
    consentGiven ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Hubspot, {}),
      /* @__PURE__ */ jsx(LinkedinInsight, {})
    ] }) : null
  ] });
}
function ProfilingProvider({ children }) {
  const { settings, locale } = usePage();
  const bannerSettings = useMemo(
    () => ({
      logoUrl: settings.primaryLogo.url,
      acceptButtonColor: settings.color_accent_500,
      backgroundColor: "#FFFFFF",
      customizeButtonColor: settings.color_accent_500,
      rejectButtonColor: settings.color_accent_500,
      textColor: "#333333",
      linksColor: settings.color_accent_500,
      acceptButtonCaptionColor: settings.color_over_accent_500,
      customizeButtonCaptionColor: settings.color_over_accent_500,
      rejectButtonCaptionColor: settings.color_over_accent_500
    }),
    [settings]
  );
  return /* @__PURE__ */ jsxs(
    IubendaConsentProvider,
    {
      locale,
      siteId: settings.iubendaSiteId,
      cookiePolicyId: settings.iubendaPolicyId,
      banner: bannerSettings,
      children: [
        /* @__PURE__ */ jsx(Profilers, {}),
        children
      ]
    }
  );
}
export {
  ProfilingProvider,
  sendFbInit,
  sendFbPixelEvent,
  sendGtagEvent,
  sendHubspotForm,
  sendTiktokPixelEvent,
  useIubendaConsent
};
